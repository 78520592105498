/* eslint react/prop-types: 0 */
import React from "react";
import { SocialIcon } from "react-social-icons";

function SocialLinks({ fill }) {
  return (
    <div>
      <nav className="">
        {[
          {
            url: `https://open.spotify.com/artist/4bOG1sx3QHFbOUVLNmMpPe?si=kANpHPfnREaBZJ8nj-mCrg`,
          },
          {
            url: `https://www.instagram.com/jasontmbk`,
          },
          {
            url: `https://www.facebook.com/timbuktuofficial`,
          },
          {
            url: `https://www.youtube.com/channel/UCyhZZGKSwd19VC9_e9e3gCg`,
          },
          {
            url: `https://twitter.com/jasontimbuktu/`,
          },
        ].map((link) => (
          <SocialIcon
            className="block text-white md:inline-block w-2"
            key={link.url}
            url={link.url}
            fgColor={fill}
            bgColor="transparent"
            style={{ height: 40, width: 40 }}
            target="_blank"
            rel="noreferrer noopener"
          >
            {link.title}
          </SocialIcon>
        ))}
      </nav>
    </div>
  );
}

export default SocialLinks;
