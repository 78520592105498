import Link from "../components/Link";
import React, { useState } from "react";
import Logo from "./Logo";
import OverlayMenu from "./OverlayMenu";
import SocialLinks from "./SocialLinks";

function Header() {
  const [isExpanded] = useState(false);

  return (
    <header className="bg-gray-900 font-mono z-50">
      <div className="flex flex-wrap items-center justify-between px-4 py-4 mx-auto">
        <Link to="/">
          <Logo />
        </Link>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:items-center w-full md:w-auto `}
        >
          {[
            {
              route: `/turne`,
              title: `Turné`,
            },
            {
              route: `https://merchworld.com/collections/timbuktu`,
              title: `Shop`,
            },
            {
              route: `https://pod.link/1518855310`,
              title: `Podcast`,
            },
            {
              route: `https://umusic-timbuk.lnk.to/sign-up`,
              title: `Mailinglista`,
            },
            {
              route: `/kontakt`,
              title: `Kontakt`,
            },
          ].map((link) => (
            <Link
              className="block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>

        <div className="hidden md:block">
          <SocialLinks fill="#ffffff" />
        </div>
        <div className="inline-block md:hidden">
          <OverlayMenu />
        </div>
      </div>
    </header>
  );
}

export default Header;
