import React, { useState, useLayoutEffect } from "react";
import Link from "../components/Link";

export default function OverlayMenu() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <div>
      <button
        className={`${
          isExpanded ? ` is-active` : ``
        } hamburger hamburger--minus relative z-50`}
        type="button"
        onClick={() => toggleExpansion(!isExpanded)}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <nav
        className={`${
          isExpanded ? `block` : `hidden`
        } fixed overflow-hidden left-0 top-0 bg-black opacity-90 h-full md:items-center w-full z-10 space-x-8`}
      >
        {isExpanded && <Modal onClose={() => toggleExpansion(!isExpanded)} />}
      </nav>
    </div>
  );
}
function Modal() {
  useLockBodyScroll();
  return (
    <div className="h-full w-full flex flex-col items-center justify-between pt-8 pb-32 text-2xl">
      {[
        {
          route: `/turne/`,
          title: `Turné`,
        },
        {
          route: `https://shop.badtasteempire.com/collections/timbuktu`,
          title: `Shop`,
        },
        {
          route: `https://pod.link/1518855310`,
          title: `Podcast`,
        },
        {
          route: `/kontakt`,
          title: `Kontakt`,
        },
        {
          route: `https://umusic.digital/timbuk/`,
          title: `Mailinglista`,
        },
        {
          route: `https://open.spotify.com/artist/4bOG1sx3QHFbOUVLNmMpPe?si=kANpHPfnREaBZJ8nj-mCrg`,
          title: `Spotify`,
        },
        {
          route: `https://www.instagram.com/jasontmbk`,
          title: `Instagram`,
        },
        {
          route: `https://www.facebook.com/timbuktuofficial`,
          title: `Facebook`,
        },
        {
          route: `https://www.youtube.com/channel/UCyhZZGKSwd19VC9_e9e3gCg`,
          title: `Youtube`,
        },
        {
          route: `https://twitter.com/jasontimbuktu/`,
          title: `Twitter`,
        },
      ].map((link) => (
        <Link
          key={link.title}
          to={link.route}
          className="font-bold uppercase text-white no-underline"
        >
          {link.title}
        </Link>
      ))}
    </div>
  );
}
function useLockBodyScroll() {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden";
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, []); // Empty array ensures effect is only run on mount and unmount
}
